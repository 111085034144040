import { ReactNode, Suspense } from "react";
import React from "react";

import type { TextSize } from "constants/WidgetConstants";
import { DEFAULT_FONT_SIZE, WIDGET_TAGS } from "constants/WidgetConstants";
import { countOccurrences } from "workers/Evaluation/helpers";

import { ValidationTypes } from "constants/WidgetValidation";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { Color } from "constants/Colors";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { TextAlign } from "../component";
import TextComponent from "../component";
import type { ContainerStyle } from "widgets/ContainerWidget/component";
import { AutocompleteDataType } from "utils/autocomplete/CodemirrorTernService";
import { OverflowTypes, FONT_STYLES } from "../constants";
import WidgetStyleContainer from "components/designSystems/appsmith/WidgetStyleContainer";
import { pick } from "lodash";
import type { Stylesheet } from "entities/AppTheming";
import IconSVG from "../icon.svg";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import { RegisteredWidgetFeatures } from "utils/WidgetFeatures";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

const MAX_HTML_PARSING_LENGTH = 1000;

const LazyTextComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class TextScreenWidget extends BaseWidget<ScreenTextWidgetProps, WidgetState> {
  static type = "SCREEN_TEXT_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "标题",
      iconSVG: IconSVG,
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
      searchTags: [
        "typography",
        "paragraph",
        "label",
        "text",
        "文字",
        "标题",
        "段落",
        "字符",
      ],
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0,
        active: true,
      },
      [RegisteredWidgetFeatures.FLOAT_LAYOUT]: {
        sectionIndex: 1,
        active: true,
      },
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      text: "文本",
      fontSize: DEFAULT_FONT_SIZE,
      fontStyle: "BOLD",
      textAlign: "LEFT",
      textColor: "#FFF",
      rows: 4,
      columns: 16,
      widgetName: "ScreenText",
      shouldTruncate: false,
      overflow: OverflowTypes.NONE,
      version: 1,
      animateLoading: true,
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "text",
            helpText: "设置文本内容",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "名称：",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: { limitLineBreaks: true },
            },
          },
          {
            sectionName: "文本主题",
            children: [
              {
                propertyName: "fontTheme",
                label: "主题",
                controlType: "IMAGE_CARD_OPTIONS",
                controlSize: "sm",
                optionType: "font",
                options: FONT_STYLES,
                isBindProperty: true,
                isTriggerProperty: false,
              },
              // {
              //   propertyName: "reflection",
              //   label: "文字倒影",
              //   helpText: "是否有倒影",
              //   controlType: "SWITCH",
              //   isJSConvertible: true,
              //   isBindProperty: true,
              //   isTriggerProperty: false,
              //   validation: { type: ValidationTypes.BOOLEAN },
              // },
              {
                propertyName: "subDesc",
                label: "底部标注",
                helpText: "标题底部小标注",
                controlType: "INPUT_TEXT",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
              {
                propertyName: "underline",
                helpText: "控制组件下划线",
                label: "下划线装饰",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
              {
                propertyName: "underlineColor",
                helpText: "控制组件下划线",
                label: "下划线装饰",
                controlType: "COLOR_PICKER",
                isBindProperty: false,
                isTriggerProperty: false,
              },
            ],
          },
          {
            propertyName: "overflow",
            label: "内容超出",
            helpText: "设置文本内容超出组件区域时如何展示",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                label: "文本滚动",
                value: OverflowTypes.SCROLL,
              },
              {
                label: "截断文本",
                value: OverflowTypes.TRUNCATE,
              },
              {
                label: "默认效果",
                value: OverflowTypes.NONE,
              },
            ],
            defaultValue: OverflowTypes.NONE,
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "isVisible",
            helpText: "控制组件的显示/隐藏",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "animateLoading",
            label: "加载时显示动画",
            controlType: "SWITCH",
            helpText: "组件依赖的数据加载时显示加载动画",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "disableLink",
            helpText: "不将文本解析成链接",
            label: "不解析链接",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      truncateButtonColor: "{{appsmith.theme.colors.primaryColor}}",
      fontFamily: "{{appsmith.theme.fontFamily.appFont}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "fontSize",
            label: "字体大小",
            helpText: "Controls the size of the font used",
            controlType: "DROP_DOWN",
            defaultValue: "1.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
        ],
      },
      {
        sectionName: "颜色配置",
        children: [
          {
            propertyName: "textColor",
            label: "文本颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "textShadow",
            label: "文本阴影",
            controlType: "INPUT_TEXT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "backgroundColor",
            label: "背景颜色",
            helpText: "Background color of the text added",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^((?![<|{{]).+){0,1}/,
                expected: {
                  type: "string (HTML 颜色名称，HEX 值)",
                  example: `red | #9C0D38`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
          },
          {
            helpText: "使用 html 颜色名称，HEX，RGB 或者 RGBA 值",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "truncateButtonColor",
            label: "截断按钮颜色",
            helpText: "Controls the color of the truncate button",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^((?![<|{{]).+){0,1}/,
              },
            },
            dependencies: ["overflow"],
            hidden: (props: ScreenTextWidgetProps) => {
              return props.overflow !== OverflowTypes.TRUNCATE;
            },
          },
        ],
      },
      {
        sectionName: "文本样式",
        children: [
          {
            propertyName: "textAlign",
            label: "对齐",
            helpText: "Controls the horizontal alignment of the text",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                startIcon: "align-left",
                value: "LEFT",
              },
              {
                startIcon: "align-center",
                value: "CENTER",
              },
              {
                startIcon: "align-right",
                value: "RIGHT",
              },
            ],
            defaultValue: "LEFT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontStyle",
            label: "强调",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "轮廓样式",
        children: [
          {
            helpText: "设置边框宽度，也可以用作外间距",
            propertyName: "borderWidth",
            label: "边框宽度",
            placeholderText: "以 px 为单位",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
        ],
      },
    ];
  }

  /**
   * Disable html parsing for long continuous texts
   * @returns boolean
   */
  shouldDisableLink = (): boolean => {
    const text = this.props.text || "";
    const count: number = countOccurrences(text, "\n", false, 0);
    return (
      (count === 0 && text.length > MAX_HTML_PARSING_LENGTH) ||
      text.length > 50000
    );
  };

  getWidgetView() {
    const disableLink: boolean = this.props.disableLink
      ? true
      : this.shouldDisableLink();
    return (
      <Suspense fallback={<Skeleton />}>
        <WidgetStyleContainer
          className="t--text-widget-container"
          {...pick(this.props, [
            "widgetId",
            "containerStyle",
            "borderColor",
            "borderWidth",
          ])}
        >
          <LazyTextComponent
            accentColor={this.props.accentColor}
            backgroundColor={this.props.backgroundColor}
            bottomRow={this.props.bottomRow}
            disableLink={disableLink}
            fontFamily={this.props.fontFamily}
            fontSize={this.props.fontSize}
            fontStyle={this.props.fontStyle}
            fontTheme={this.props.fontTheme}
            isLoading={this.props.isLoading}
            key={this.props.widgetId}
            leftColumn={this.props.leftColumn}
            overflow={this.props.overflow}
            reflection={this.props.reflection}
            rightColumn={this.props.rightColumn}
            subDesc={this.props.subDesc}
            text={this.props.text}
            textAlign={this.props.textAlign ? this.props.textAlign : "LEFT"}
            textColor={this.props.textColor}
            textShadow={this.props.textShadow}
            topRow={this.props.topRow}
            truncateButtonColor={
              this.props.truncateButtonColor || this.props.accentColor
            }
            underlineColor={
              this.props.underline ? this.props.underlineColor : ""
            }
            widgetId={this.props.widgetId}
          />
        </WidgetStyleContainer>
      </Suspense>
    );
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.text }}`,
    };
  }
}

export interface TextStyles {
  backgroundColor?: string;
  textColor?: string;
  textShadow?: string;
  fontStyle?: string;
  fontSize?: TextSize;
  textAlign?: TextAlign;
  truncateButtonColor?: string;
  fontFamily: string;
  fontTheme?: string;
  reflection?: boolean;
  subDesc?: string;
  underline?: boolean;
  underlineColor?: string;
}

export interface ScreenTextWidgetProps extends WidgetProps, TextStyles {
  accentColor: string;
  text?: string;
  isLoading: boolean;
  disableLink: boolean;
  widgetId: string;
  containerStyle?: ContainerStyle;
  children?: ReactNode;
  borderColor?: Color;
  borderWidth?: number;
  overflow: OverflowTypes;
}

export default TextScreenWidget;
