import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { ValidationTypes } from "constants/WidgetValidation";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { AutocompletionDefinitions } from "WidgetProvider/constants";
import CustomComponentComponent from "../component";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import { newTestCode } from "../constants";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

const LazyCustomComponentComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class CustomComponentWidget extends BaseWidget<
  CustomComponentWidgetProps,
  WidgetState
> {
  static type = "CUSTOMCOMPONENT_WIDGET";

  static getConfig() {
    return {
      name: "自定义组件", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      searchTags: ["custom", "component", "自定义", "react"],
      tags: [WIDGET_TAGS.FEATRUE],
    };
  }

  static getDefaults() {
    return {
      widgetName: "CustomComponent",
      rows: 20,
      columns: 20,
      version: 1,
      isVisible: true,
      model: {
        name: "小美",
        text: "你好啊！",
        query: "Query1",
        jsFunName: "JSObject1.myFun1",
        other: "test",
      },
      code: newTestCode,
    };
  }

  static getAutoLayoutConfig() {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "customcompoent",
      "!url": "",
      model: "any",
      code: "any",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            helpText: "控制组件的显示/隐藏",
            propertyName: "isVisible",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "model",
            label: "默认数据",
            helpText: "自定义组件的默认初始化数据",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.OBJECT,
            },
          },
          {
            propertyName: "code",
            label: "代码",
            helpText: "自定义组件的代码",
            controlType: "JS_DATA",
            placeholderText: "<p>Inline HTML</p>",
            isBindProperty: true,
            isTriggerProperty: false,
            dependencies: ["model"],
            evaluatedDependencies: ["model"],
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      modelData: "model",
      codeData: "code",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      modelData: undefined,
      codeData: undefined,
    };
  }

  componentDidUpdate(
    prevProps: CustomComponentWidgetProps,
    prevState?: WidgetState | undefined,
  ): void {}

  //变化
  onModelChange = (data: any) => {
    this.props.updateWidgetMetaProperty("modelData", {
      ...this.props.modelData,
      ...data,
    });
  };

  // 执行查询
  onExcuteQueryAction = (data: any) => {
    const params = data.params ? JSON.stringify(data.params) : "";
    super.executeAction({
      triggerPropertyName: "onExcuteQueryAction",
      dynamicString: `{{${data.queryName}.run(${params})}}`,
      event: {
        type: EventType.ON_JS_FUNCTION_EXECUTE,
      },
    });
  };

  // 执行JS
  onRunJS = (data: any) => {
    const params = data.params ? JSON.stringify(data.params) : "";
    super.executeAction({
      triggerPropertyName: "onExcuteJsAction",
      dynamicString: `{{${data.queryName}(${params})}}`,
      event: {
        type: EventType.ON_JS_FUNCTION_EXECUTE,
      },
    });
  };

  getWidgetView() {
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyCustomComponentComponent
          code={this.props.codeData}
          model={this.props.modelData || {}}
          onExcuteQueryAction={this.onExcuteQueryAction}
          onModelChange={this.onModelChange}
          onRunJS={this.onRunJS}
          widgetId={this.props.widgetId}
        />
      </Suspense>
    );
  }
}

export interface CustomComponentWidgetProps extends WidgetProps {
  code: any;
  model: any;
  onModelChange: (v: any) => void;
}

export default CustomComponentWidget;
