import DropTargetComponent, {
  useUpdateRows,
} from "layoutSystems/common/dropTarget/DropTargetComponent";
import type { DropTargetComponentProps } from "layoutSystems/common/dropTarget/DropTargetComponent";
import type { ReactNode } from "react";
import { memo } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { getWidget } from "sagas/selectors";
import type { AppState } from "ee/reducers";
import {
  GridDefaults,
  MAIN_CONTAINER_WIDGET_ID,
} from "constants/WidgetConstants";
import { protectedModeSelector } from "selectors/gitSyncSelectors";
import { LayoutSystemTypes } from "layoutSystems/types";
import { getLayoutSystemType } from "selectors/layoutSystemSelectors";

interface DropTargetComponentWrapperProps {
  dropTargetProps: DropTargetComponentProps;
  dropDisabled: boolean;
  children: ReactNode;
  snapColumnSpace: number;
  currentAppType: string;
}

/**
 * This component is a wrapper for the DropTargetComponent.
 * It decides whether to render the DropTargetComponent or not based on the dropDisabled prop.
 */

export const DropTargetComponentWrapper = memo(
  ({
    children,
    dropDisabled,
    dropTargetProps,
    currentAppType,
  }: DropTargetComponentWrapperProps) => {
    // This code block is added exclusively to handle List Widget Meta Canvas Widget which is generated via template.
    const widget = useSelector((state: AppState) =>
      getWidget(state, dropTargetProps.parentId || MAIN_CONTAINER_WIDGET_ID),
    );
    const isProtectedMode = useSelector(protectedModeSelector);
    const isDragging = useSelector(
      (state: AppState) => state.ui.widgetDragResize.isDragging,
    );
    const layoutSystemType: LayoutSystemTypes =
      useSelector(getLayoutSystemType);
    const isAutoLayoutActive = layoutSystemType === LayoutSystemTypes.AUTO;
    const { contextValue, dropTargetRef, rowRef } = useUpdateRows(
      dropTargetProps.bottomRow,
      dropTargetProps.widgetId,
      dropTargetProps.parentId,
      dropTargetProps.mobileBottomRow,
      dropTargetProps.isMobile,
      isAutoLayoutActive,
      dropTargetProps.isListWidgetCanvas,
    );
    if (
      (dropTargetProps.parentId && !widget) ||
      isProtectedMode ||
      dropDisabled
    ) {
      // Get the height for the drop target
      const height = `${rowRef.current * GridDefaults.DEFAULT_GRID_ROW_HEIGHT}px`;
      const dropTargetStyles = {
        height: dropTargetProps.isListWidgetCanvas
          ? isDragging
            ? "100%"
            : "auto"
          : height,
      };
      //eslint-disable-next-line
      return (
        <>
          {currentAppType === "module" ? (
            <div
              className={`t--drop-target drop-target-${
                dropTargetProps.parentId || MAIN_CONTAINER_WIDGET_ID
              }`}
              style={dropTargetStyles}
            >
              {children}
            </div>
          ) : (
            <>{children}</>
          )}
        </>
      );
    }
    return (
      <DropTargetComponent {...dropTargetProps}>{children}</DropTargetComponent>
    );
  },
);
