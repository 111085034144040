import React, { lazy, Suspense } from "react";
import { IconNames } from "@blueprintjs/icons";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import AvatarComponent from "../component";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { LabelPosition } from "components/constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import {
  BlueprintOperationTypes,
  type AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import { RegisteredWidgetFeatures } from "utils/WidgetFeatures";
import type { DynamicPath } from "utils/DynamicBindingUtils";
import { isDynamicValue } from "utils/DynamicBindingUtils";
import { get } from "lodash";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

const ICON_NAMES = Object.keys(IconNames).map(
  (name: string) => IconNames[name as keyof typeof IconNames],
);

const LazyAvatarComponent = lazy(async () =>
  retryPromise(async () => import("../component")),
);

class AvatarWidget extends BaseWidget<AvatarWidgetProps, WidgetState> {
  constructor(props: AvatarWidgetProps) {
    super(props);
    this.onImageClick = this.onImageClick.bind(this);
    this.onTextClick = this.onTextClick.bind(this);
  }
  static type = "AVATAR_WIDGET";

  static getConfig() {
    return {
      name: "头像",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.FEATRUE],
      searchTags: ["avatar", "user", "photo"],
    };
  }

  static getDefaults() {
    return {
      widgetName: "Avatar",
      imageSrc:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      AvatarType: "image",
      backgroundColor: "#858282",
      labelVisible: true,
      titleVisible: true,
      defaultImage:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      label: "{{global.user.name}}",
      icon: JSON.stringify({
        iconName: "icon-user",
        type: "antd",
        category: "website",
      }),
      title: "{{global.user.email}}",
      labelFontStyle: "BOLD",
      titleFontStyle: "BOLD",
      textPosition: "Right",
      textAlignment: "Left",
      rows: 10,
      columns: 20,
      version: 1,
      blueprint: {
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              if (!isDynamicValue(widget.label) || !widget.title) {
                return [];
              }

              const dynamicBindingPathList: DynamicPath[] = [
                ...get(widget, "dynamicBindingPathList", []),
              ];

              dynamicBindingPathList.push(
                {
                  key: "label",
                },
                {
                  key: "title",
                },
              );

              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "dynamicBindingPathList",
                  propertyValue: dynamicBindingPathList,
                },
              ];

              return updatePropertyMap;
            },
          },
        ],
      },
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 2,
        active: true,
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "120px",
              minHeight: "40px",
            };
          },
        },
      ],
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      imageSrc: "string",
      icon: "string",
      label: "string",
      title: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setIcon: {
          path: "icon",
          type: "string",
        },
        setLabel: {
          path: "label",
          type: "string",
        },
        setTitle: {
          path: "title",
          type: "string",
        },
        setImageSrc: {
          path: "imageSrc",
          type: "string",
        },
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "修改头像类型",
            propertyName: "AvatarType",
            label: "头像类型",
            enableSearch: true,
            controlType: "DROP_DOWN",
            defaultValue: "image",
            options: [
              { label: "图片", value: "image" },
              { label: "图标", value: "icon" },
            ],
            virtual: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            helpText: "图片地址或者 Base64 数据",
            propertyName: "imageSrc",
            label: "图片",
            controlType: "INPUT_TEXT",
            placeholderText: "输入图片 URL / Base64",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.IMAGE_URL },
            hidden: (props: AvatarWidgetProps) => props.AvatarType === "icon",
            dependencies: ["AvatarType"],
          },
          {
            helpText: "图片加载失败时显示的默认图片",
            propertyName: "defaultImage",
            label: "默认图片",
            controlType: "INPUT_TEXT",
            placeholderText: "输入图片 URL / Base64",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.IMAGE_URL },
            hidden: (props: AvatarWidgetProps) => props.AvatarType === "icon",
            dependencies: ["AvatarType"],
          },
          {
            propertyName: "icon",
            label: "图标",
            helpText: "设置头像图标",
            controlType: "ANTD_ICON_SELECT",
            // defaultIconName: "plus",
            virtual: true,
            hideNoneIcon: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            // validation: {
            //   type: ValidationTypes.TEXT,
            //   // params: {
            //   //   allowedValues: ICON_NAMES,
            //   //   default: IconNames.PLUS,
            //   // },
            // },
            hidden: (props: AvatarWidgetProps) => props.AvatarType === "image",
            dependencies: ["AvatarType"],
          },
        ],
      },
      {
        sectionName: "文本",
        children: [
          {
            propertyName: "labelVisible",
            helpText: "是否显示头像上文本",
            label: "label",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "label",
            helpText: "设置头像上文本",
            label: "",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: { limitLineBreaks: true },
            },
            hidden: (props: AvatarWidgetProps) => props.labelVisible === false,
            dependencies: ["labelVisible"],
          },
          {
            propertyName: "titleVisible",
            helpText: "是否显示头像上文本",
            label: "title",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "title",
            helpText: "设置头像下文本",
            label: "",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: { limitLineBreaks: true },
            },
            hidden: (props: AvatarWidgetProps) => props.titleVisible === false,
            dependencies: ["titleVisible"],
          },
          {
            helpText: "设置文本位置",
            propertyName: "textPosition",
            label: "位置",
            controlType: "ICON_TABS",
            options: [
              { label: "左", value: LabelPosition.Left },
              { label: "右", value: LabelPosition.Right },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "textAlignment",
            helpText: "设置文本与头像的对齐方式",
            label: "对齐",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            options: [
              {
                startIcon: "contract-left-line",
                value: LabelPosition.Left,
              },
              {
                startIcon: "contract-right-line",
                value: LabelPosition.Right,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "isVisible",
            helpText: "控制组件的显示/隐藏",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "animateLoading",
            label: "加载时显示动画",
            controlType: "SWITCH",
            helpText: "组件依赖的数据加载时显示加载动画",
            defaultValue: false,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "事件",
        children: [
          {
            helpText: "当用户点击图片时触发",
            propertyName: "onAvatarClick",
            label: "onAvatarClick",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "当用户点击文本时触发",
            propertyName: "onTextClick",
            label: "onTextClick",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "头像样式",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "边框圆角样式",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText: "组件轮廓投影",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "size",
            label: "大小",
            helpText: "大小",
            controlType: "DROP_DOWN",
            defaultValue: 24,
            options: [
              { label: "16px", value: 16 },
              { label: "24px", value: 24 },
              { label: "32px", value: 32 },
              { label: "40px", value: 40 },
              { label: "48px", value: 48 },
            ],
            isJSConvertible: true,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "backgroundColor",
            helpText: "设置头像背景色",
            label: "背景颜色",
            controlType: "COLOR_PICKER",
            defaultValue: "#d4d4d8",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
        ],
      },
      {
        sectionName: "文本样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "label文本颜色",
            helpText: "设置label的颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "labelFontStyle",
            label: "强调",
            helpText: "设置label字体样式",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "titleTextColor",
            label: "title文本颜色",
            helpText: "设置title的颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "titleFontStyle",
            label: "强调",
            helpText: "设置title字体样式",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  getWidgetView() {
    const {
      AvatarType,
      backgroundColor,
      borderRadius,
      boxShadow,
      defaultImage,
      icon,
      imageSrc,
      label,
      labelFontStyle,
      labelTextColor,
      labelVisible,
      size,
      textAlignment,
      textPosition,
      title,
      titleFontStyle,
      titleTextColor,
      titleVisible,
    } = this.props;
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyAvatarComponent
          avatarType={AvatarType}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          boxShadow={boxShadow}
          defaultAvatarUrl={defaultImage}
          icon={icon}
          imageSrc={imageSrc}
          label={label}
          labelFontStyle={labelFontStyle}
          labelTextColor={labelTextColor}
          labelVisible={labelVisible}
          onAvatarClick={this.onImageClick}
          onTextClick={this.onTextClick}
          size={size}
          textAlignment={textAlignment}
          textPosition={textPosition}
          title={title}
          titleFontStyle={titleFontStyle}
          titleTextColor={titleTextColor}
          titleVisible={titleVisible}
        />
      </Suspense>
    );
  }

  onImageClick() {
    super.executeAction({
      triggerPropertyName: "onAvatarClick",
      dynamicString: this.props.onAvatarClick,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  }

  onTextClick() {
    super.executeAction({
      triggerPropertyName: "onTextClick",
      dynamicString: this.props.onTextClick,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  }
}

export interface AvatarWidgetProps extends WidgetProps {
  imageSrc: string;
  AvatarType: "image" | "icon";
  defaultImage: string;
  icon?: string;
  borderRadius?: string;
  labelFontStyle?: string;
  onAvatarClick?: any;
  onTextClick?: any;
  size?: number;
  label?: string;
  labelVisible?: boolean;
  title?: string;
  labelTextColor?: string;
  titleTextColor?: string;
  titleFontStyle?: string;
  titleVisible?: boolean;
  textPosition?: "Left" | "Right";
  textAlignment?: "Left" | "Right";
}

export default AvatarWidget;
